
import { Options, Vue } from "vue-class-component";
import HeaderShadow from "@/components/utils/HeaderShadow.vue";
import PageHeading from "@/components/utils/PageHeading.vue";
import Input from "@/components/utils/Input.vue";
import Button from "@/components/utils/Button.vue";
import UAParser from "ua-parser-js";

@Options({
  data() {
    return {
      sessionId: null,
      parser: new UAParser(),
    };
  },
  components: {
    HeaderShadow,
    PageHeading,
    Input,
    Button,
  },
  computed: {
    reconnectCount(): number {
      return this.$store.state.session.reconnect;
    },
  },
  mounted() {
    // this.$nextTick(() => {
    this.checkIfTouchDevice();
    this.checkIfApple(this.parser);
    this.checkIfMobile(this.parser);
    this.checkIfTablet(this.parser);
    // });
  },
  methods: {
    accessRemoteClient() {
      console.log("Session ID", this.sessionId);

      this.$router.push("/remote-client/" + this.sessionId);
    },
    checkIfTouchDevice() {
      if ("ontouchstart" in document.documentElement) {
        console.log("touch device");

        this.$store.dispatch("isTouchDevice", true);
        this.$store.dispatch("telestrationMobileActive", false);
      }
    },
    checkIfApple(uaParser: UAParser) {
      if (
        uaParser.getDevice().vendor === "Apple" ||
        uaParser.getOS().name === "iOS" ||
        uaParser.getOS().name === "Mac OS"
      ) {
        this.$store.dispatch("isApple", true);
      }
    },
    checkIfMobile(uaParser: UAParser) {
      if (uaParser.getDevice().type === "mobile") {
        this.$store.dispatch("isMobile", true);
      }
    },
    checkIfTablet(uaParser: UAParser) {
      if (uaParser.getDevice().type === "tablet") {
        this.$store.dispatch("isTablet", true);
      }
    },
  },
})
export default class RemoteClient extends Vue {}
